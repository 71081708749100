<template>

<div class="badge">

	<div v-if="badge" class="badge-selected">

		<div class="badge-selected-avatar" v-bgimage="badge.avatar">

			<app-icon icon="user" v-if="!badge.avatar" />

		</div>

		<div class="badge-selected-info">

			<div class="badge-selected-info-name">{{ badge.name }}</div>
			<div class="badge-selected-info-bgg" v-if="badge.bgg">{{ badge.bgg }}</div>

		</div>

		<app-icon v-if="!locked" icon="cancel" class="badge-selected-cancel" v-on:click.native="onCancelClick" />

	</div>

	<div v-if="!badge && !locked" class="badge-scan" v-on:click="is.scanning = true">

		<app-icon icon="checkout.scan" class="badge-scan-icon" />
		Tap to scan badge

	</div>

	<com-scan :allowClose="true" :allowManual="true" v-on:scanned="onScanned" v-on:close="is.scanning = false" :failed="is.failed" :checking="is.checking" v-if="is.scanning" />

</div>

</template>

<script>

export default {

	props: ['badge', 'locked', 'ignoreLimit'],

	components: {
		'com-scan': () => import('../common/Scan')
	},

	data: function() {

		return {
			is: {
				scanning: false,
				checking: false,
				failed: false
			},
			errors: {
				limit: 'Too many active loans',
				notfound: 'Cannot identify badge'
			}
		}

	},

	methods: {

		onCancelClick: function() {

			this.$emit('change', false)

		},

		onScanned: function(value) {

			this.is.checking = true
			this.is.failed = false

			this.$api.get(['checkout/badge', value], {
				ignoreLimit: (this.ignoreLimit) ? 1 : 0
			}).then(function(json) {

				this.$emit('change', {
					value: json.badge.id,
					badge: json.badge
				})

				this.is.scanning = false
				this.is.checking = false

			}.bind(this), function(json) {

				this.is.checking = false
				this.is.failed = json.reason

				this.$notify({
					message: this.errors[json.reason],
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.badge-scan {
	margin: 0px 10px;
	border: 1px dashed #d85f5f;
	height: 64px;
	align-items: center;
	text-align: center;
	font-size: 16px;
	flex-shrink: 0;
	font-weight: 500;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	color: #d85f5f;
	margin-bottom: 10px;
	border-radius: 4px;
	cursor: pointer;
}

.badge-scan-icon {
	font-size: 32px;
	width: 64px;
	margin-right: 10px;
}

.badge-selected {
	height: 64px;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	background-color: #4d5b6e;
	border-radius: 4px;
	align-items: center;
	margin: 0px 10px 10px 10px;
	color: #fff;
}

.badge-selected-avatar {
	text-align: center;
	line-height: 64px;
	font-size: 32px;
	color: #fff;
	width: 64px;
	border-radius: 4px 0px 0px 4px;
	margin-right: 10px;
	background-size: cover;
	background-position: 50% 50%;
	height: 64px;
	flex-shrink: 0;
	background-color: #4d5b6e;
}

.badge-selected-info {
	flex-grow: 1;
}

.badge-selected-info-name {
	font-weight: 400;
	font-size: 16px;
}

.badge-selected-info-bgg {
	font-weight: 300;
	font-size: 15px;
	opacity: 0.75;
}

.badge-selected-cancel {
	font-size: 24px;
	margin-right: 10px;
	cursor: pointer;
}

</style>
